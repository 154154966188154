import { useDispatch, useSelector } from 'react-redux';
import { placeOrder, setCart } from 'src/redux/actions';
import { RootState } from 'src/redux/reducers';
import { addDeliveryInfoInCart } from 'src/utils/addDeliveryInfoInCart';
import { getStringifyAddress } from 'src/utils/getStringifyAddress';
import router from 'next/router';

export function useCreateOrder() {
  const { cart } = useSelector((state: RootState) => ({
    cart: state.cartReducer,
  }));

  const dispatch = useDispatch();
  function addAddressDetailsAndPlaceOrder(callback = null) {
    const orderData = addDeliveryInfoInCart(cart, getStringifyAddress());
    dispatch(
      setCart(orderData, () => {
        dispatch(placeOrder({ ...orderData }, router, callback));
      })
    );
  }
  return { addAddressDetailsAndPlaceOrder };
}
